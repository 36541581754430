import { Button, Modal } from "react-bootstrap";
import React from "react";
import Spinner from "../Spinner";

const ConfirmModal = ({
  modal,
  toggle,
  onHandler,
  title = "확인",
  message = "컨트렉트를 배포할까요?",
  loading,
  setLoading,
  selectDeployData,
}) => {
  return (
    <Modal show={modal} onHide={toggle} size="md">
      <Modal.Header onHide={toggle} closeButton>
        <h4 className="modal-title">{title}</h4>
      </Modal.Header>
      <Modal.Body>
        <div>{message}</div>
      </Modal.Body>
      <Modal.Footer>
        {!loading ? (
          <Button variant="primary" onClick={onHandler}>
            확인
          </Button>
        ) : (
          <Button color="primary" disabled>
            <Spinner
              className="spinner-border-sm me-1"
              tag="span"
              color="white"
            />
            처리중...
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
