import { toast } from "react-toastify";

export const notify = (isSuccess, message = null) => {
  const successMessage = "성공적으로 처리 되었습니다.";
  const failMessage = "요청에 실패했습니다.";
  if (isSuccess) {
    return toast.success(message ? message : successMessage);
  }
  return toast.error(message ? message : failMessage);
};
