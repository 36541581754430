import useInput from "../../hooks/useInput";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { FormInput, VerticalForm } from "../index";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import {
  getContractPool,
  getLiquidities,
  updateContractPool,
} from "../../api/contract";
import { notify } from "../../utils/toast";
import moment from "moment";

const { Header, Body, Footer } = Modal;
const DropDownContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  > div > div {
    height: 200px;
    overflow-y: auto;
  }

  .bottom-start {
    height: 120px;
  }
`;
const UpdateContractPoolModal = ({ modal, toggle, selectId, getLists }) => {
  const [network, setNetwork] = useState("BSC");
  const [locked, setLocked] = useState(false);
  const [start, setStart] = useState(new Date());
  const [finish, setFinish] = useState(new Date());
  const [name, onChangeName, setName] = useInput("");
  const [address, onChangeAddress, setAddress] = useInput("");
  const [minimum, onChangeMinimum, setMinimum] = useInput("");
  const [memo, onChangeMemo, setMemo] = useInput("");
  const [isPrivate, setIsPrivate] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [seq, onChangeSeq, setSeq] = useInput("");
  const [liquidity, onChangeLiquidity, setLiquidity] = useInput("");
  const [reward, setReward] = useState("");
  const [staking, setStaking] = useState("");
  const [skipLiquidity, setSkipLiquidity] = useState(false);

  const [poolNetwork, setPoolNetwork] = useState([]);

  const NetworkDropDown = () => {
    return ["ETH", "BSC", "HECO"].map((pool, i) => (
      <Dropdown.Item
        key={i}
        onClick={(e) => {
          setNetwork(e.target.textContent);
        }}
      >
        {pool}
      </Dropdown.Item>
    ));
  };

  const onSubmitHandler = async () => {
    const body = {
      name,
      start: moment(start).unix() * 1000,
      finish: moment(finish).unix() * 1000,
      address: address ? address : null,
      minimum,
      memo,
      private: isPrivate,
      locked,
      network,
      enabled: enabled ? 1 : 0,
      liquidity: Number(liquidity),
      seq: Number(seq),
      staking: staking ? staking : null,
      reward: reward ? reward : null,
      skipLiquidity,
    };
    try {
      if (selectId) {
        await updateContractPool(selectId, body);
        await getLists();
        notify(true);
        toggle();
      }
    } catch (err) {
      notify(false);
    }
  };

  const RewardDropDown = () => {
    return poolNetwork.map((item, i) => (
      <Dropdown.Item
        key={i}
        onClick={(e) => {
          setReward(e.target.textContent.split(" ")[1]);
        }}
      >
        {`[${item.type}] ${item.contract}`}
      </Dropdown.Item>
    ));
  };

  const StakingDropDown = () => {
    return poolNetwork.map((item, i) => (
      <Dropdown.Item
        key={i}
        onClick={(e) => {
          setStaking(e.target.textContent.split(" ")[1]);
        }}
      >
        {`[${item.type}] ${item.contract}`}
      </Dropdown.Item>
    ));
  };

  const bootstrap = async () => {
    if (selectId) {
      const data = await getLiquidities();
      setPoolNetwork(data);
      const result = await getContractPool(selectId);
      setAddress(result.address);
      setFinish(new Date(result.finish));
      setLocked(result.locked);
      setMemo(result.memo ? result.memo : "");
      setMinimum(result.minimum);
      setName(result.name);
      setNetwork(result.network);
      setIsPrivate(result.private);
      setStart(new Date(result.start));
      setLiquidity(result.liquidity);
      setSeq(result.seq);
      setEnabled(result.enabled ? true : false);
      setStaking(result.staking);
      setReward(result.reward);
      setSkipLiquidity(result.skipLiquidity);
    }
  };

  useEffect(() => {
    bootstrap();
  }, [modal]);

  return (
    <>
      <Modal show={modal} onHide={toggle} size="md">
        <Header
          onHide={() => {
            toggle();
          }}
          closeButton
        >
          <h4 className="modal-title">풀수정</h4>
        </Header>

        <Body>
          <form>
            <Row>
              <Col md={12}>
                <FormInput
                  label="이름"
                  type="text"
                  name="name"
                  placeholder="이름를 입력하세요"
                  containerClass={"mb-3"}
                  value={name}
                  onChange={onChangeName}
                />
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <FormInput
                  label="주소"
                  type="text"
                  name="address"
                  placeholder="주소를 입력하세요"
                  containerClass={"mb-3"}
                  value={address}
                  onChange={onChangeAddress}
                />
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <Form.Label>reward</Form.Label>
                <DropDownContainer>
                  <DropdownButton variant="light" title={reward}>
                    {RewardDropDown()}
                  </DropdownButton>
                </DropDownContainer>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <Form.Label>staking</Form.Label>
                <DropDownContainer>
                  <DropdownButton variant="light" title={staking}>
                    {StakingDropDown()}
                  </DropdownButton>
                </DropDownContainer>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <Form.Label>network</Form.Label>
                <DropDownContainer>
                  <DropdownButton variant="light" title={network}>
                    {NetworkDropDown()}
                  </DropdownButton>
                </DropDownContainer>
              </Col>

              <Col md={6}>
                <FormInput
                  label="minimum"
                  type="number"
                  name="minimum"
                  placeholder="minimum를 입력하세요"
                  containerClass={"mb-3"}
                  value={minimum}
                  onChange={(e) => {
                    let amount = parseInt(e.target.value);
                    if (amount < 0) {
                      return;
                    }
                    setMinimum(amount);
                  }}
                  min="0"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Label>Locked</Form.Label>
                <div>
                  <BootstrapSwitchButton
                    onstyle="outline-primary"
                    offstyle="outline-secondary"
                    width={100}
                    checked={locked}
                    onlabel="Locked"
                    offlabel="Unlocked"
                    onChange={() => {
                      setLocked(!locked);
                    }}
                  />
                </div>
              </Col>

              <Col md={6}>
                <Form.Label>Private 여부</Form.Label>
                <div>
                  <BootstrapSwitchButton
                    onstyle="outline-primary"
                    offstyle="outline-secondary"
                    width={100}
                    checked={isPrivate}
                    onlabel="ON"
                    offlabel="OFF"
                    onChange={() => {
                      setIsPrivate(!isPrivate);
                    }}
                  />
                </div>
              </Col>
            </Row>

            <Row style={{ marginTop: "20px" }}>
              <Col md={6}>
                <Form.Label>활성화 여부</Form.Label>
                <div>
                  <BootstrapSwitchButton
                    onstyle="outline-primary"
                    offstyle="outline-secondary"
                    width={100}
                    checked={enabled}
                    onlabel="ON"
                    offlabel="OFF"
                    onChange={() => {
                      setEnabled(!enabled);
                    }}
                  />
                </div>
              </Col>

              <Col md={6}>
                <FormInput
                  label="memo"
                  type="text"
                  name="memo"
                  placeholder="memo를 입력하세요"
                  containerClass={"mb-3"}
                  value={memo}
                  onChange={onChangeMemo}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Label>start</Form.Label>
                <DatePicker
                  selected={start}
                  dateFormat="yyyy/MM/dd HH:mm"
                  onChange={(date) => setStart(date)}
                  selectsStart
                  startDate={start}
                  endDate={finish}
                  style={{ width: "100px" }}
                  showTimeSelect
                />
              </Col>
              <Col md={6}>
                <Form.Label>finish</Form.Label>
                <DatePicker
                  selected={finish}
                  dateFormat="yyyy/MM/dd HH:mm"
                  onChange={(date) => setFinish(date)}
                  selectsEnd
                  startDate={start}
                  endDate={finish}
                  minDate={start}
                  showTimeSelect
                />
              </Col>
            </Row>

            <Row style={{ marginTop: "10px" }}>
              <Col md={6}>
                <FormInput
                  label="seq"
                  type="number"
                  name="seq"
                  placeholder="seq 입력하세요"
                  containerClass={"mb-3"}
                  value={seq}
                  onChange={onChangeSeq}
                />
              </Col>

              <Col md={6}>
                <FormInput
                  label="liquidity"
                  type="number"
                  name="liquidity"
                  placeholder="liquidity 입력하세요"
                  containerClass={"mb-3"}
                  value={liquidity}
                  onChange={(e) => {
                    let amount = parseInt(e.target.value);
                    if (amount < 1) {
                      return;
                    }
                    setLiquidity(amount);
                  }}
                  min="1"
                />
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Label>skip liquidity</Form.Label>
                <div>
                  <BootstrapSwitchButton
                    onstyle="outline-primary"
                    offstyle="outline-secondary"
                    width={100}
                    checked={skipLiquidity}
                    onlabel="활성화"
                    offlabel="비활성화"
                    onChange={() => {
                      setSkipLiquidity(!skipLiquidity);
                    }}
                  />
                </div>
              </Col>
            </Row>
          </form>
        </Body>

        <Footer>
          <Button variant="primary" type="submit" onClick={onSubmitHandler}>
            수정
          </Button>
        </Footer>
      </Modal>
    </>
  );
};

export default UpdateContractPoolModal;
