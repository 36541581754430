import useInput from "../../hooks/useInput";
import React, { useEffect, useState } from "react";
import { getAdminAccount, updateAdminAccount } from "../../api/account";

import {
  Col,
  Form,
  Row,
  Modal,
  Dropdown,
  DropdownButton,
  Button,
  Badge,
} from "react-bootstrap";
import { FormInput } from "../index";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Spinner from "../Spinner";
import { notify } from "../../utils/toast";

const UpdateAdminAccountModal = ({
  show,
  onHide,
  selectId,
  passwordToggle,
  getLists,
}) => {
  const [name, onChangeName, setName] = useInput("");
  const [loading, setLoading] = useState(false);

  const resetUserData = () => {
    setName("");
  };

  const updateUserHandler = async () => {
    try {
      if (selectId) {
        setLoading(true);
        await updateAdminAccount(selectId, {
          name,
        });
        await getLists();
        onHide();
        notify(true);
        setLoading(false);
      }
    } catch (err) {
      // onHide();
      setLoading(false);
      notify(false);
    }
  };

  useEffect(() => {
    if (show) {
      bootstrap();
    }
  }, [show]);

  const bootstrap = async () => {
    if (selectId) {
      const result = await getAdminAccount(selectId);
      setName(result.name);
    }
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={() => {
          onHide();
          resetUserData();
        }}
      >
        <Modal.Header onHide={onHide} closeButton>
          <h4 className="modal-title">정보 수정</h4>
        </Modal.Header>

        <Modal.Body>
          <>
            <Row className="row-container">
              <Col lg={6}>
                <form>
                  <FormInput
                    label="이름"
                    type="text"
                    name="name"
                    placeholder="이름을 입력하세요."
                    value={name}
                    onChange={onChangeName}
                  />
                </form>
              </Col>
            </Row>

            {/*<Row>*/}
            {/*  <Col md={6} style={{ marginTop: "20px" }}>*/}
            {/*    /!*<label>비밀번호 수정</label>*!/*/}
            {/*    <div*/}
            {/*      style={{*/}
            {/*        display: "flex",*/}
            {/*        alignItems: "center",*/}
            {/*        marginBottom: "24px",*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      <Button onClick={passwordToggle}>비밀번호 수정</Button>*/}
            {/*    </div>*/}
            {/*  </Col>*/}
            {/*</Row>*/}
          </>
        </Modal.Body>
        <Modal.Footer>
          {!loading ? (
            <Button
              variant="primary"
              onClick={updateUserHandler}
              loading={true}
            >
              확인
            </Button>
          ) : (
            <Button color="primary" disabled>
              <Spinner
                className="spinner-border-sm me-1"
                tag="span"
                color="white"
              />
              처리중...
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UpdateAdminAccountModal;
