import React, { useEffect } from "react";
import PageTitle from "../../components/PageTitle";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import styled from "styled-components";
import { useState } from "react";
import Spinner from "../../components/Spinner";
import moment from "moment";
import classNames from "classnames";
import Table from "../../components/Table";
import {
  getAllContractPool,
  requestWithdraw,
  userRequestWithdraw,
} from "../../api/contract";
import useInput from "../../hooks/useInput";
import { notify } from "../../utils/toast";
import { ToastContainer } from "react-toastify";
import _ from "underscore";

const CardWrapper = styled(Card)`
  @media (min-width: 768px) {
    margin-left: 150px;
    margin-right: 150px;
  }

  @media (max-width: 768px) {
    margin-bottom: 60px;
  }
`;

const DropDownContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  > div > div {
    height: 200px;
    overflow-y: auto;
  }
`;
const PoolWithdrawPage = () => {
  const [contractHistory, setContractHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  const [contractLists, setContractLists] = useState([]);
  const [currentContract, setCurrentContract] = useState(null);
  const [withdrawAddress, onChangeWithdrawAddress, setWithdrawAddress] =
    useInput("");
  const [coinCount, onChangeCoinCount, setCoinCount] = useInput("");
  console.log(currentContract, "current");
  const statusText = (status) => {
    switch (status) {
      case 0:
        return "대기";
      case 1:
        return "완료";
      case 2:
        return "실패";
      default:
        return "진행 중";
    }
  };
  const ContractDropDownItem = () => {
    return contractLists.map((p, i) => {
      return (
        <Dropdown.Item
          key={i}
          style={{ overflowY: "auto" }}
          onClick={(e) => {
            console.log(p);
            setCurrentContract(p);
          }}
        >
          {`${p.name}: [${p.address}]`}
        </Dropdown.Item>
      );
    });
  };
  const StatusColumn = ({ row }) => {
    return (
      <>
        <h5>
          <span
            // style={{ width: '37px', paddingRight: '34px' }}
            className={classNames("badge", {
              "badge-success-lighten": row.original.status === 1,
              "badge-danger-lighten": row.original.status === 2,
              // 'badge-warning-lighten': row.original.status === 2,
              "badge-info-lighten": row.original.status === 0,
            })}
          >
            {/*{row.original.status === 1 && <i className="mdi mdi-coin me-1"></i>}*/}
            {/*{row.original.status === 2 && <i className="mdi mdi-cancel me-1"></i>}*/}
            {/*{row.original.status === 0 && <i className="mdi mdi-cash me-1"></i>}*/}
            {/*{row.original.payment_status === 'Awaiting Authorization' && (*/}
            {/*    <i className="mdi mdi-timer-sand me-1"></i>*/}
            {/*)}*/}
            {statusText(row.original.status)}
          </span>
        </h5>
      </>
    );
  };

  const ActionColumn = ({ row }) => {
    return (
      <>
        {row.original.url ? (
          <a href={row.original.url} target={"_blank"} className="action-icon">
            <i className="uil-globe" />{" "}
          </a>
        ) : (
          <a className="action-icon">-</a>
        )}
        {/*</Link> : <i className="uil-globe" />}*/}
        {/*<Link to="/" className="action-icon">*/}
        {/*    {' '}*/}
        {/*    <i className="mdi mdi-eye"></i>*/}
        {/*</Link>*/}
        {/*<Link to="/" className="action-icon">*/}
        {/*    {' '}*/}
        {/*    <i className="mdi mdi-square-edit-outline"></i>*/}
        {/*</Link>*/}
        {/*<Link to="/" className="action-icon">*/}
        {/*    {' '}*/}
        {/*    <i className="mdi mdi-delete"></i>*/}
        {/*</Link>*/}
      </>
    );
  };

  const sizePerPageList = [
    {
      text: "10",
      value: 10,
    },
    {
      text: "20",
      value: 20,
    },
    {
      text: "50",
      value: 50,
    },
    {
      text: "100",
      value: 100,
    },
  ];
  const DateColumn = ({ row }) => {
    return (
      <>
        {moment(row.original.timestamp).format("YYYY-MM-DD")}{" "}
        <small className="text-muted">
          {moment(row.original.timestamp).format("HH:mm:ss")}
        </small>
      </>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "타입",
        accessor: "type",
        Cell: (data) => {
          return (
            <div>
              {data.row.cells[0].row.original.type ? (
                <span style={{ color: "#38d9a9" }}>출금</span>
              ) : (
                <span style={{ color: "#f03e3e" }}>입금</span>
              )}
            </div>
          );
        },
      },
      {
        Header: "상태",
        accessor: "status",
        // sort: true,
        Cell: StatusColumn,
      },
      {
        Header: "from",
        accessor: "fromadd",
      },
      {
        Header: "to",
        accessor: "toadd",
        // Cell: (data) => <span>{data}</span>,
      },
      {
        Header: "갯수",
        accessor: "value",
      },
      // {
      //     Header: 'txid',
      //     accessor: 'txid',
      //     Cell: ({ row }) => <>{row.original.txid === null ? '-' : row.original.txid}</>,
      // },
      {
        Header: "스캔보기",
        accessor: "url",
        Cell: ActionColumn,
      },
      {
        Header: "날짜",
        accessor: "timestamp",
        Cell: DateColumn,
      },
    ],
    [JSON.stringify(contractHistory)]
  );

  const onWithdraw = async () => {
    if (withdrawAddress && coinCount) {
      try {
        const body = {
          seq: currentContract.seq,
          network: currentContract.network,
          address: withdrawAddress,
          amount: coinCount,
        };

        setLoading(true);
        await userRequestWithdraw(currentContract.idx, body);

        setLoading(false);
        notify(true);
        // bootstrap();
        setCoinCount("");
        setWithdrawAddress("");
      } catch (err) {
        setLoading(false);
        notify(false);
      }
    }
  };

  const bootstrap = async () => {
    const poolLists = await getAllContractPool();
    // let allChargerLists = [];
    // Object.keys(poolLists.chargeList).forEach((key, i) => {
    //   allChargerLists.push(...poolLists.chargeList[key]);
    // });
    // allChargerLists = allChargerLists.filter((list) => {
    //   if (list.address) {
    //     return list;
    //   }
    // });
    const allChargerLists = _.flatten(
      Object.keys(poolLists.chargeList).map((key, i) => {
        return poolLists.chargeList[key];
      })
    ).filter((list) => {
      if (list.address) {
        return list;
      }
    });

    setContractLists(allChargerLists);

    setCurrentContract(allChargerLists[0]);
  };

  useEffect(() => {
    bootstrap();
  }, []);

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "강제출금", path: "/contract/withdraw", active: true },
        ]}
        title="강제출금"
      />

      <Row>
        <Col>
          <CardWrapper>
            <Card.Body>
              <div>
                <Row className="row-container" style={{ marginTop: "30px" }}>
                  <Col lg={6}>
                    <Form.Label>컨트랙트</Form.Label>
                    <DropDownContainer>
                      <DropdownButton
                        variant="light"
                        title={`${currentContract?.name}: [${currentContract?.address}]`}
                        onChange={(e) => {
                          console.log(e, "e");
                        }}
                      >
                        {ContractDropDownItem()}
                      </DropdownButton>
                    </DropDownContainer>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Form.Label>
                      <div> 출금 할 사용자 주소</div>
                    </Form.Label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "24px",
                      }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        value={withdrawAddress}
                        onChange={onChangeWithdrawAddress}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <Form.Label>
                      <div>코인 갯수</div>
                    </Form.Label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "24px",
                      }}
                    >
                      <input
                        type="number"
                        className="form-control"
                        value={coinCount}
                        onChange={onChangeCoinCount}
                      />
                    </div>
                  </Col>
                </Row>

                <Row style={{ marginTop: "30px" }}>
                  <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                    {!loading ? (
                      <Button
                        variant="primary"
                        disabled={!(withdrawAddress && coinCount)}
                        onClick={onWithdraw}
                      >
                        확인
                      </Button>
                    ) : (
                      <Button color="primary" disabled>
                        <Spinner
                          className="spinner-border-sm me-1"
                          tag="span"
                          color="white"
                        />
                        처리중...
                      </Button>
                    )}
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </CardWrapper>
        </Col>
      </Row>

      <div style={{ marginTop: "30px" }}>
        <Table
          columns={columns}
          data={contractHistory}
          pageSize={20}
          sizePerPageList={sizePerPageList}
          isSortable={true}
          pagination={true}
          isSearchable={true}
          theadClass="table-light"
          searchBoxClass="mb-2"
        />
      </div>
      <ToastContainer />
    </>
  );
};

export default PoolWithdrawPage;
