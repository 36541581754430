import { Modal, Form, Button } from "react-bootstrap";

const PasswordChangeModal = ({
  passwordModal,
  passwordToggle,
  currentPassword,
  onChangeCurrentPassword,
  changePassword,
  onChangeChangePassword,
  setCurrentPassword,
  setChangePassword,
  updatePassword,
  id,
}) => {
  return (
    <Modal show={passwordModal} onHide={passwordToggle} size={"md"}>
      <Modal.Header onHide={passwordToggle} closeButton>
        <h4 className="modal-title">비밀번호 수정</h4>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Form.Label>
            <div>변경 할 비밀번호</div>
          </Form.Label>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "24px",
            }}
          >
            <input
              type="password"
              className="form-control"
              value={changePassword}
              onChange={onChangeChangePassword}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            passwordToggle();
            setCurrentPassword("");
            setChangePassword("");
          }}
        >
          닫기
        </Button>
        <Button variant="primary" onClick={updatePassword}>
          확인
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PasswordChangeModal;
