import { APICore, AUTH_SESSION_KEY } from "../helpers/api/apiCore";
import axios from "axios";

const api = new APICore();

const accountUrl = "/managers/users";

export const getAccountInfo = async () => {
  const baseUrl = "/account/authenticated";
  let token = localStorage.getItem(AUTH_SESSION_KEY);
  if (token) {
    token = token.slice(1, token.length - 1);
    axios.defaults.headers.common["Authentication"] = token;
  }
  return await axios.get(`${baseUrl}`).then((resp) => resp.data.data);
};

export const getAllAdminAccount = async () => {
  const baseUrl = "/managers/users";
  const params = { from: 0, count: 50000 };
  return await api.get(baseUrl, params).then((resp) => resp.data.data.value);
};

export const addAdminAccount = async (body) => {
  const baseUrl = "/managers/account/admin";
  return await api.create(`${baseUrl}`, body);
};

export const getAdminAccount = async (uid) => {
  return await api.get(`${accountUrl}/${uid}`).then((resp) => resp.data.data);
};

export const updateAdminAccount = async (uid, body) => {
  return await api.create(`${accountUrl}/${uid}`, body);
};

export const changePasswordUserInfo = async (body) => {
  const baseUrl = "/managers/changepass";
  return await api.create(baseUrl, body).then((resp) => resp);
};

export const updateAccountInfo = async (body) => {
  const baseUrl = "/account/profile";
  return await api.create(baseUrl, body).then((resp) => {
    return resp;
  });
};

export const changePasswordMyInfo = async (body) => {
  const baseUrl = "/account/changepass";
  return await api.create(baseUrl, body).then((resp) => resp);
};

export const deleteAdminAccount = async (uid) => {
  const baseUrl = "/managers/users";

  return await api.delete(`${baseUrl}/${uid}`).then((resp) => resp.data);
};
