import { Button, Modal } from "react-bootstrap";
import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { FormInput, VerticalForm } from "../index";
import { toast, ToastContainer } from "react-toastify";
import { notify } from "../../utils/toast";
import { addAdminAccount } from "../../api/account";

const AddAdminAccountModal = ({ modal, toggle, bootstrap }) => {
  const { Header, Footer, Body } = Modal;

  const onSubmit = async (formData) => {
    const { id, password, name, contact } = formData;
    const body = { email: id, password, name };
    try {
      await addAdminAccount(body);
      bootstrap();
      toggle();
      notify(true);
    } catch (err) {
      if (err === "이미 등록되어 있습니다") {
        notify(false, "이미 등록되어 있는 이메일입니다.");
        return;
      }
      notify(false);
    }
  };
  const schemaResolver = yupResolver(
    yup.object().shape({
      name: yup.string().required("이름을 입력하세요"),
      id: yup.string().required("이메일를 입력하세요"),
      password: yup.string().required("패스워드를 입력하세요"),
      // contact: yup.string().required("연락처를 입력하세요"),
    })
  );

  return (
    <Modal show={modal} onHide={toggle} size="md">
      <Header onHide={toggle} closeButton>
        <h4 className="modal-title">계정 추가</h4>
      </Header>

      <Body>
        <VerticalForm
          onSubmit={onSubmit}
          resolver={schemaResolver}
          defaultValues={{}}
        >
          <FormInput
            label="아아디"
            type="text"
            name="id"
            placeholder="아이디를 입력하세요"
            containerClass={"mb-3"}
          />

          <FormInput
            label="패스워드"
            type="password"
            name="password"
            placeholder="패스워드를 입력하세요"
            containerClass={"mb-3"}
          />

          <FormInput
            label="이름"
            type="text"
            name="name"
            placeholder="이름을 입력하세요"
            containerClass={"mb-3"}
          />

          {/*<FormInput*/}
          {/*  label="연락처"*/}
          {/*  type="text"*/}
          {/*  name="contact"*/}
          {/*  placeholder="연락처를 입력하세요"*/}
          {/*  containerClass={"mb-3"}*/}
          {/*/>*/}
          <Button variant="primary" type="submit">
            생성
          </Button>
        </VerticalForm>
      </Body>
    </Modal>
  );
};

export default AddAdminAccountModal;
