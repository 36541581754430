import PageTitle from "../../components/PageTitle";
import { Col, DropdownButton, Row, Form, Card, Button } from "react-bootstrap";
import useInput from "../../hooks/useInput";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  changePasswordMyInfo,
  getAccountInfo,
  updateAccountInfo,
} from "../../api/account";
import ChangeMyInfoPasswordModal from "../../components/Modal/ChangeMyPasswordModal";
import MyInfoSaveConfirmModal from "../../components/Modal/MyInfoSaveConfirmModal";

export const BANKNAME = [
  "산업은행",
  "기업은행",
  "국민은행",
  "외환은행",
  "수협",
  "농협",
  "우리은행",
  "SC제일은행",
  "씨티은행",
  "대구은행",
  "부산은행",
  "광주은행",
  "제주은행",
  "전북은행",
  "경남은행",
  "새마을금고",
  "신협",
  "상호저축은행",
  "모건스탠리",
  "HSBC은행",
  "도이치은행",
  "미즈호은행",
  "미쓰비시도쿄UFJ은행",
  "BOA은행",
  "비엔피파리바은행",
  "중국공상은행",
  "중국은행",
  "산림조합중앙회",
  "우체국",
  "하나은행",
  "신한은행",
  "케이뱅크",
  "카카오뱅크",
  "유안타증권",
  "KB증권",
  "미래에셋대우",
  "삼성증권",
  "NH투자증권",
  "하이투자증권",
  "대신증권",
  "신한금융투자",
  "DB금융투자",
  "한국투자증권",
];

const CardWrapper = styled(Card)`
  @media (min-width: 768px) {
    margin-left: 150px;
    margin-right: 150px;
  }

  @media (max-width: 768px) {
    margin-bottom: 60px;
  }
`;

const HrStyle = styled.hr`
  border: none;
  border-top: 1px dashed #495057;
  color: #fff;
  background-color: #fff;
  height: 1px;
  width: 100%;
`;

const DropDownContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  > div > div {
    height: 200px;
    overflow-y: auto;
  }
`;
const MyInfoPage = () => {
  const [name, onChangeName, setName] = useInput("");
  const [currentPassword, onChangeCurrentPassword, setCurrentPassword] =
    useInput("");
  const [newPassword, onChangeNewPassword, setNewPassword] = useInput("");
  const [id, setId] = useState("");
  const [uid, setUid] = useState("");
  const [contact, onChangeContact, setContact] = useInput("");
  const [modal, setModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);

  const passwordToggle = () => {
    setPasswordModal(!passwordModal);
  };

  const toggle = () => {
    setModal(!modal);
  };

  const updatePassword = async () => {
    try {
      await changePasswordMyInfo({ old: currentPassword, new: newPassword });
      alert("정상적으로 변경되었습니다.");
      passwordToggle();
      setNewPassword("");
      setCurrentPassword("");
    } catch (err) {
      if (err === "유효하지 않은 비밀번호 입니다") {
        alert("비밀번호가 일치하지 않습니다");
        return;
      }
      alert("비밀번호 변경에 실패했습니다.");
    }
  };

  const onSubmit = async () => {
    try {
      const body = { name };
      await updateAccountInfo(body);
      alert("정상적으로 수정되었습니다.");
      bootstrap();
      toggle();
    } catch (err) {
      alert("에러가 발생했습니다. 잠시후 다시 시도해주세요.");
    }
  };

  const bootstrap = async () => {
    const result = await getAccountInfo();
    const { name, email, uid } = result;
    setName(name);
    setId(email);
    setUid(uid);
  };

  useEffect(() => {
    bootstrap();
  }, []);

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "프로필", path: "/account/myInfo", active: true },
        ]}
        title="프로필"
      />

      <Row>
        <Col>
          <CardWrapper>
            <Card.Body>
              <div>
                <h5 className="mb-4 text-uppercase">
                  <i className="mdi mdi-account-circle me-1" /> 계정정보
                </h5>

                <Row>
                  <Col md={6}>
                    <Form.Label>
                      <div>아이디</div>
                    </Form.Label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "24px",
                      }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        value={id}
                        readOnly
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <Form.Label>
                      <div>uid</div>
                    </Form.Label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "24px",
                      }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        value={uid}
                        readOnly
                      />
                    </div>
                  </Col>
                </Row>

                <HrStyle />
                <Row>
                  <h5 className="mb-4 text-uppercase">
                    <i className="mdi mdi-account-settings-outline me-1" />{" "}
                    개인정보
                  </h5>

                  <Col md={6}>
                    <Form.Label>
                      <div>이름</div>
                    </Form.Label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "24px",
                      }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        value={name}
                        onChange={onChangeName}
                      />
                    </div>
                  </Col>

                  {/*<Col md={6}>*/}
                  {/*    <Form.Label>*/}
                  {/*        <div>휴대폰 번호</div>*/}
                  {/*    </Form.Label>*/}
                  {/*    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '24px' }}>*/}
                  {/*        <input*/}
                  {/*            type="text"*/}
                  {/*            className="form-control"*/}
                  {/*            value={contact}*/}
                  {/*            onChange={onChangeContact}*/}
                  {/*        />*/}
                  {/*    </div>*/}
                  {/*</Col>*/}
                  <Col md={6}>
                    <Form.Label>
                      <div style={{ marginBottom: "15px" }} />
                    </Form.Label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "24px",
                      }}
                    >
                      <Button onClick={passwordToggle}>패스워드 변경</Button>
                    </div>
                  </Col>
                </Row>

                <Row></Row>

                <div className="text-end">
                  <button
                    className="btn btn-success mt-2"
                    onClick={toggle}
                    disabled={!name}
                  >
                    <i className="mdi mdi-content-save" /> 저장
                  </button>
                </div>

                <HrStyle />
              </div>
            </Card.Body>
          </CardWrapper>
        </Col>
      </Row>
      <MyInfoSaveConfirmModal
        modal={modal}
        toggle={toggle}
        onSubmit={onSubmit}
      />
      <ChangeMyInfoPasswordModal
        passwordModal={passwordModal}
        passwordToggle={passwordToggle}
        currentPassword={currentPassword}
        onChangeCurrentPassword={onChangeCurrentPassword}
        newPassword={newPassword}
        onChangeNewPassword={onChangeNewPassword}
        setCurrentPassword={setCurrentPassword}
        setNewPassword={setNewPassword}
        updatePassword={updatePassword}
      />
    </>
  );
};
export default MyInfoPage;
