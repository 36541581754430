import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import { Link } from "react-router-dom";
import moment from "moment";
import styled from "styled-components";

import {
  changePasswordUserInfo,
  deleteAdminAccount,
  getAllAdminAccount,
} from "../../api/account";
import { Button, Card, Col, Row } from "react-bootstrap";
import Table from "../../components/Table";
import { sizePerPageList } from "../../constants/tableSizePerPageList";
import AddAdminAccountModal from "../../components/Modal/AddAdminAccountModal";
import UpdateAdminAccount from "../../components/Modal/UpdateAdminAccount";
import useInput from "../../hooks/useInput";
import PasswordChangeModal from "../../components/Modal/PasswordChangeModal";
import { ToastContainer } from "react-toastify";
import BaseDeleteModal from "../../components/BaseDeleteModal";
import { notify } from "../../utils/toast";

const RowContainer = styled(Row)`
  width: 70%;
  margin: 0 auto;
  section {
    position: relative;
  }

  .add-btn {
    position: absolute;
    right: 0;
  }
`;

const AdminAccountPage = () => {
  const [adminAccountLists, setAdminAccountLists] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [selectId, setSelectId] = useState("");
  const [passwordModal, setPasswordModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [currentPassword, onChangeCurrentPassword, setCurrentPassword] =
    useInput("");
  const [changePassword, onChangeChangePassword, setChangePassword] =
    useInput("");

  const deleteToggle = () => {
    setDeleteModal(!deleteModal);
  };

  const deleteAdminAccountHandler = async () => {
    try {
      if (selectId) {
        await deleteAdminAccount(selectId);
        notify(true);
        deleteToggle();
        bootstrap();
      }
    } catch (err) {
      console.log(err, "err");
      notify(false);
    }
  };

  const updatePassword = async () => {
    try {
      if (selectId) {
        await changePasswordUserInfo({ uid: selectId, new: changePassword });
        alert("정상적으로 변경되었습니다.");
        passwordToggle();
        setChangePassword("");
        setCurrentPassword("");
      }
    } catch (err) {
      if (err === "유효하지 않은 비밀번호 입니다") {
        alert("비밀번호가 일치하지 않습니다");
        return;
      }
      alert("비밀번호 변경에 실패했습니다.");
    }
  };

  const passwordToggle = () => {
    setPasswordModal(!passwordModal);
  };
  const updateToggle = () => {
    setUpdateModal(!updateModal);
  };

  const addToggle = () => {
    setAddModal(!addModal);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "uid",
        accessor: "uid",
        Cell: (data) => {
          console.log(data.row.original.uid);
          return (
            <div
              onClick={() => {
                setSelectId(data.row.original.uid);
                updateToggle();
              }}
              style={{ cursor: "pointer", color: "#4dabf7" }}
            >
              {data.row.original.uid}
            </div>
          );
        },
      },
      {
        Header: "이름",
        accessor: "name",
      },
      {
        Header: "이메일",
        accessor: "email",
      },
      {
        Header: "일시",
        accessor: "timestamp",
        sort: true,
        Cell: (data) => (
          <div>{moment(data.row.cells[3].value).format("YYYY-MM-DD")}</div>
        ),
      },

      {
        Header: "액션",
        accessor: "idx",
        Cell: (data) => {
          return (
            <>
              <span
                className="action-icon"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSelectId(data.row.original.uid);
                  deleteToggle();
                }}
              >
                <i className="mdi mdi-delete" />
              </span>
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(adminAccountLists)]
  );
  const bootstrap = async () => {
    const data = await getAllAdminAccount();
    setAdminAccountLists(data);
  };
  useEffect(() => {
    bootstrap();
  }, []);

  console.log(adminAccountLists);
  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "계정 관리", path: "/account/admin/lists", active: true },
        ]}
        title="계정 관리"
      />

      <RowContainer>
        <Col>
          <Card>
            <Card.Body>
              <section>
                <Button className="add-btn" onClick={addToggle}>
                  추가
                </Button>
                <Table
                  columns={columns}
                  data={adminAccountLists}
                  pageSize={20}
                  sizePerPageList={sizePerPageList}
                  isSortable={true}
                  pagination={true}
                  isSearchable={true}
                  theadClass="table-light"
                  searchBoxClass="mt-2 mb-3"
                />
              </section>
            </Card.Body>
          </Card>
        </Col>
      </RowContainer>
      <AddAdminAccountModal
        modal={addModal}
        toggle={addToggle}
        bootstrap={bootstrap}
      />

      <UpdateAdminAccount
        selectId={selectId}
        show={updateModal}
        onHide={updateToggle}
        passwordToggle={passwordToggle}
        getLists={bootstrap}
      />

      <PasswordChangeModal
        passwordModal={passwordModal}
        passwordToggle={passwordToggle}
        currentPassword={currentPassword}
        onChangeCurrentPassword={onChangeCurrentPassword}
        changePassword={changePassword}
        onChangeChangePassword={onChangeChangePassword}
        setCurrentPassword={setCurrentPassword}
        setChangePassword={setChangePassword}
        updatePassword={updatePassword}
        id={selectId}
      />

      <BaseDeleteModal
        modal={deleteModal}
        toggle={deleteToggle}
        onHandler={deleteAdminAccountHandler}
      />
      <ToastContainer />
    </>
  );
};

export default AdminAccountPage;
