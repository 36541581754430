import { Button, Modal } from "react-bootstrap";

const MyInfoSaveConfirmModal = ({ modal, toggle, onSubmit }) => {
  return (
    <Modal show={modal} onHide={toggle} size="md">
      <Modal.Header onHide={toggle} closeButton>
        <h4 className="modal-title">프로필 수정</h4>
      </Modal.Header>
      <Modal.Body>정말로 수정하시겠습니까?</Modal.Body>
      <Modal.Footer>
        <Button onClick={toggle}>닫기</Button>
        <Button variant="primary" onClick={onSubmit}>
          확인
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MyInfoSaveConfirmModal;
