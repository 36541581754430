import React from "react";
import { Redirect } from "react-router-dom";
import { Route } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import MyInfoPage from "../pages/account/MyInfo";
import PoolListsPage from "../pages/contract/PoolLists";
import AdminAccountPage from "../pages/account/AdminAccount";
import PoolWithdrawPage from "../pages/contract/PoolWithdraw";
import PoolAccessPage from "../pages/contract/PoolAccess";
import UserWithdrawPage from "../pages/contract/UserWithdraw";

// lazy load all the views

// auth
const Login = React.lazy(() => import("../pages/account/Login"));
const Logout = React.lazy(() => import("../pages/account/Logout"));
const Register = React.lazy(() => import("../pages/account/Register"));
const Confirm = React.lazy(() => import("../pages/account/Confirm"));
const ForgetPassword = React.lazy(() =>
  import("../pages/account/ForgetPassword")
);
const LockScreen = React.lazy(() => import("../pages/account/LockScreen"));

const Login2 = React.lazy(() => import("../pages/account2/Login2"));
const Logout2 = React.lazy(() => import("../pages/account2/Logout2"));
const Register2 = React.lazy(() => import("../pages/account2/Register2"));
const Confirm2 = React.lazy(() => import("../pages/account2/Confirm2"));
const ForgetPassword2 = React.lazy(() =>
  import("../pages/account2/ForgetPassword2")
);
const LockScreen2 = React.lazy(() => import("../pages/account2/LockScreen2"));

// dashboard
const AnalyticsDashboard = React.lazy(() =>
  import("../pages/dashboard/Analytics")
);
const EcommerceDashboard = React.lazy(() =>
  import("../pages/dashboard/Ecommerce")
);
const CRMDashboard = React.lazy(() => import("../pages/dashboard/CRM"));
const ProjectDashboard = React.lazy(() => import("../pages/dashboard/Project"));

// apps
const CalendarApp = React.lazy(() => import("../pages/apps/Calendar/"));
const Projects = React.lazy(() => import("../pages/apps/Projects/"));
const ProjectDetail = React.lazy(() =>
  import("../pages/apps/Projects/Detail/")
);
const ProjectGannt = React.lazy(() => import("../pages/apps/Projects/Gantt/"));
const ProjectForm = React.lazy(() =>
  import("../pages/apps/Projects/ProjectForm")
);
// - chat
const ChatApp = React.lazy(() => import("../pages/apps/Chat/"));
// - ecommece pages
const EcommerceProducts = React.lazy(() =>
  import("../pages/apps/Ecommerce/Products")
);
const ProductDetails = React.lazy(() =>
  import("../pages/apps/Ecommerce/ProductDetails")
);
const Orders = React.lazy(() => import("../pages/apps/Ecommerce/Orders"));
const OrderDetails = React.lazy(() =>
  import("../pages/apps/Ecommerce/OrderDetails")
);
const Customers = React.lazy(() => import("../pages/apps/Ecommerce/Customers"));
const Cart = React.lazy(() => import("../pages/apps/Ecommerce/Cart"));
const Checkout = React.lazy(() => import("../pages/apps/Ecommerce/Checkout/"));
const Sellers = React.lazy(() => import("../pages/apps/Ecommerce/Sellers"));
// - email
const Inbox = React.lazy(() => import("../pages/apps/Email/Inbox"));
const EmailDetail = React.lazy(() => import("../pages/apps/Email/Detail"));
// - social
const SocialFeed = React.lazy(() => import("../pages/apps/SocialFeed/"));
// - tasks
const TaskList = React.lazy(() => import("../pages/apps/Tasks/List/"));
const TaskDetails = React.lazy(() => import("../pages/apps/Tasks/Details"));
const Kanban = React.lazy(() => import("../pages/apps/Tasks/Board/"));
// - file
const FileManager = React.lazy(() => import("../pages/apps/FileManager"));

// pages
const Profile = React.lazy(() => import("../pages/profile"));
const Profile2 = React.lazy(() => import("../pages/profile2"));
const ErrorPageNotFound = React.lazy(() =>
  import("../pages/error/PageNotFound")
);
const ErrorPageNotFoundAlt = React.lazy(() =>
  import("../pages/error/PageNotFoundAlt")
);
const ServerError = React.lazy(() => import("../pages/error/ServerError"));
// - other
const Invoice = React.lazy(() => import("../pages/other/Invoice"));
const FAQ = React.lazy(() => import("../pages/other/FAQ"));
const Pricing = React.lazy(() => import("../pages/other/Pricing"));
const Maintenance = React.lazy(() => import("../pages/other/Maintenance"));
const Starter = React.lazy(() => import("../pages/other/Starter"));
const PreLoader = React.lazy(() => import("../pages/other/PreLoader/"));
const Timeline = React.lazy(() => import("../pages/other/Timeline"));

const Landing = React.lazy(() => import("../pages/landing/"));

// uikit
const Accordions = React.lazy(() => import("../pages/uikit/Accordions"));
const Alerts = React.lazy(() => import("../pages/uikit/Alerts"));
const Avatars = React.lazy(() => import("../pages/uikit/Avatars"));
const Badges = React.lazy(() => import("../pages/uikit/Badges"));
const Breadcrumbs = React.lazy(() => import("../pages/uikit/Breadcrumb"));
const Buttons = React.lazy(() => import("../pages/uikit/Buttons"));
const Cards = React.lazy(() => import("../pages/uikit/Cards"));
const Carousels = React.lazy(() => import("../pages/uikit/Carousel"));
const Dropdowns = React.lazy(() => import("../pages/uikit/Dropdowns"));
const EmbedVideo = React.lazy(() => import("../pages/uikit/EmbedVideo"));
const Grid = React.lazy(() => import("../pages/uikit/Grid"));
const ListGroups = React.lazy(() => import("../pages/uikit/ListGroups"));
const Modals = React.lazy(() => import("../pages/uikit/Modals"));
const Notifications = React.lazy(() => import("../pages/uikit/Notifications"));
const Offcanvases = React.lazy(() => import("../pages/uikit/Offcanvas"));
const Paginations = React.lazy(() => import("../pages/uikit/Paginations"));
const Popovers = React.lazy(() => import("../pages/uikit/Popovers"));
const Progress = React.lazy(() => import("../pages/uikit/Progress"));
const Ribbons = React.lazy(() => import("../pages/uikit/Ribbons"));
const Spinners = React.lazy(() => import("../pages/uikit/Spinners"));
const Tabs = React.lazy(() => import("../pages/uikit/Tabs"));
const Tooltips = React.lazy(() => import("../pages/uikit/Tooltips"));
const Typography = React.lazy(() => import("../pages/uikit/Typography"));
const DragDrop = React.lazy(() => import("../pages/uikit/DragDrop"));
const RangeSliders = React.lazy(() => import("../pages/uikit/RangeSliders"));
const Ratings = React.lazy(() => import("../pages/uikit/Ratings"));

// icons
const Dripicons = React.lazy(() => import("../pages/uikit/Dripicons"));
const MDIIcons = React.lazy(() => import("../pages/uikit/MDIIcons"));
const Unicons = React.lazy(() => import("../pages/uikit/Unicons"));

// forms
const BasicForms = React.lazy(() => import("../pages/forms/Basic"));
const FormAdvanced = React.lazy(() => import("../pages/forms/Advanced"));
const FormValidation = React.lazy(() => import("../pages/forms/Validation"));
const FormWizard = React.lazy(() => import("../pages/forms/Wizard"));
const FileUpload = React.lazy(() => import("../pages/forms/FileUpload"));
const Editors = React.lazy(() => import("../pages/forms/Editors"));

// charts
const ApexChart = React.lazy(() => import("../pages/charts/Apex"));
const BriteChart = React.lazy(() => import("../pages/charts/Brite"));
const ChartJs = React.lazy(() => import("../pages/charts/ChartJs"));

// tables
const BasicTables = React.lazy(() => import("../pages/tables/Basic"));
const AdvancedTables = React.lazy(() => import("../pages/tables/Advanced"));

// widgets
const Widgets = React.lazy(() => import("../pages/uikit/Widgets"));

// maps
const GoogleMaps = React.lazy(() => import("../pages/maps/GoogleMaps"));
const VectorMaps = React.lazy(() => import("../pages/maps/VectorMaps"));

// root routes
const rootRoute = {
  path: "/",
  exact: true,
  component: () => <Redirect to="/contract/pool-lists" />,
  route: PrivateRoute,
};

// dashboards
const dashboardRoutes = {
  path: "/dashboard",
  name: "Dashboards",
  icon: "uil-home-alt",
  header: "Navigation",
  children: [
    {
      path: "/dashboard/analytics",
      name: "Analytics",
      component: AnalyticsDashboard,
      route: PrivateRoute,
    },
    {
      path: "/dashboard/crm",
      name: "CRM",
      component: CRMDashboard,
      route: PrivateRoute,
    },
    {
      path: "/dashboard/ecommerce",
      name: "Ecommerce",
      badge: {
        variant: "success",
        text: "3",
      },
      component: EcommerceDashboard,
      route: PrivateRoute,
    },
    {
      path: "/dashboard/project",
      name: "Project",
      component: ProjectDashboard,
      route: PrivateRoute,
    },
  ],
};

const calendarAppRoutes = {
  path: "/apps/calendar",
  name: "Calendar",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "uil-calender",
  component: CalendarApp,
  header: "Apps",
};

const chatAppRoutes = {
  path: "/apps/chat",
  name: "Chat",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "uil-comments-alt",
  component: ChatApp,
};

const ecommerceAppRoutes = {
  path: "/apps/ecommerce",
  name: "eCommerce",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "uil-store",
  children: [
    {
      path: "/apps/ecommerce/products",
      name: "Products",
      component: EcommerceProducts,
      route: PrivateRoute,
    },
    {
      path: "/apps/ecommerce/details",
      name: "Product Details",
      component: ProductDetails,
      route: PrivateRoute,
    },
    {
      path: "/apps/ecommerce/orders",
      name: "Orders",
      component: Orders,
      route: PrivateRoute,
    },
    {
      path: "/apps/ecommerce/order/details",
      name: "Order Details",
      component: OrderDetails,
      route: PrivateRoute,
    },
    {
      path: "/apps/ecommerce/customers",
      name: "Customers",
      component: Customers,
      route: PrivateRoute,
    },
    {
      path: "/apps/ecommerce/shopping-cart",
      name: "Shopping Cart",
      component: Cart,
      route: PrivateRoute,
    },
    {
      path: "/apps/ecommerce/checkout",
      name: "Checkout",
      component: Checkout,
      route: PrivateRoute,
    },
    {
      path: "/apps/ecommerce/sellers",
      name: "Sellers",
      component: Sellers,
      route: PrivateRoute,
    },
  ],
};

const emailAppRoutes = {
  path: "/apps/email",
  name: "Email",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "uil-envelope",
  children: [
    {
      path: "/apps/email/inbox",
      name: "Inbox",
      component: Inbox,
      route: PrivateRoute,
    },
    {
      path: "/apps/email/details",
      name: "Email Details",
      component: EmailDetail,
      route: PrivateRoute,
    },
  ],
};

const projectAppRoutes = {
  path: "/apps/projects",
  name: "Projects",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "uil-briefcase",

  children: [
    {
      path: "/apps/projects/list",
      name: "List",
      component: Projects,
      route: PrivateRoute,
    },
    {
      path: "/apps/projects/details",
      name: "Detail",
      component: ProjectDetail,
      route: PrivateRoute,
    },
    {
      path: "/apps/projects/gantt",
      name: "Gantt",
      component: ProjectGannt,
      route: PrivateRoute,
    },
    {
      path: "/apps/projects/new",
      name: "Create Project",
      component: ProjectForm,
      route: PrivateRoute,
    },
  ],
};

const socialAppRoutes = {
  path: "/apps/social",
  name: "Social Feed",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "uil-rss",
  component: SocialFeed,
};

const taskAppRoutes = {
  path: "/apps/tasks",
  name: "Tasks",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "uil-clipboard-alt",
  children: [
    {
      path: "/apps/tasks/list",
      name: "Task List",
      component: TaskList,
      route: PrivateRoute,
    },
    {
      path: "/apps/tasks/details",
      name: "Task List",
      component: TaskDetails,
      route: PrivateRoute,
    },
    {
      path: "/apps/tasks/kanban",
      name: "Kanban",
      component: Kanban,
      route: PrivateRoute,
    },
  ],
};

const fileAppRoutes = {
  path: "/apps/file",
  name: "File Manager",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "uil-folder-plus",
  component: FileManager,
};

const appRoutes = [
  calendarAppRoutes,
  chatAppRoutes,
  ecommerceAppRoutes,
  emailAppRoutes,
  projectAppRoutes,
  socialAppRoutes,
  taskAppRoutes,
  fileAppRoutes,
];

// pages
const pageRoutes = {
  path: "/pages",
  name: "Pages",
  icon: "uil-copy-alt",
  header: "Custom",
  children: [
    {
      path: "/pages/profile",
      name: "Profile",
      component: Profile,
      route: PrivateRoute,
    },
    {
      path: "/pages/profile2",
      name: "Profile2",
      component: Profile2,
      route: PrivateRoute,
    },
    {
      path: "/pages/invoice",
      name: "Invoice",
      component: Invoice,
      route: PrivateRoute,
    },
    {
      path: "/pages/faq",
      name: "FAQ",
      component: FAQ,
      route: PrivateRoute,
    },
    {
      path: "/pages/pricing",
      name: "Pricing",
      component: Pricing,
      route: PrivateRoute,
    },
    {
      path: "/pages/error-404-alt",
      name: "Error - 404-alt",
      component: ErrorPageNotFoundAlt,
      route: PrivateRoute,
    },
    {
      path: "/pages/starter",
      name: "Starter Page",
      component: Starter,
      route: PrivateRoute,
    },
    {
      path: "/pages/preloader",
      name: "Starter Page",
      component: PreLoader,
      route: PrivateRoute,
    },
    {
      path: "/pages/timeline",
      name: "Timeline",
      component: Timeline,
      route: PrivateRoute,
    },
  ],
};

// ui
const uiRoutes = {
  path: "/ui",
  name: "Components",
  icon: "uil-package",
  header: "UI Elements",
  children: [
    {
      path: "/ui/base",
      name: "Base UI",
      children: [
        {
          path: "/ui/accordions",
          name: "Accordions",
          component: Accordions,
          route: PrivateRoute,
        },
        {
          path: "/ui/alerts",
          name: "Alerts",
          component: Alerts,
          route: PrivateRoute,
        },
        {
          path: "/ui/avatars",
          name: "Avatars",
          component: Avatars,
          route: PrivateRoute,
        },
        {
          path: "/ui/badges",
          name: "Badges",
          component: Badges,
          route: PrivateRoute,
        },
        {
          path: "/ui/breadcrumb",
          name: "Breadcrumb",
          component: Breadcrumbs,
          route: PrivateRoute,
        },
        {
          path: "/ui/buttons",
          name: "Buttons",
          component: Buttons,
          route: PrivateRoute,
        },
        {
          path: "/ui/cards",
          name: "Cards",
          component: Cards,
          route: PrivateRoute,
        },
        {
          path: "/ui/carousel",
          name: "Carousel",
          component: Carousels,
          route: PrivateRoute,
        },
        {
          path: "/ui/dropdowns",
          name: "Dropdowns",
          component: Dropdowns,
          route: PrivateRoute,
        },
        {
          path: "/ui/embedvideo",
          name: "EmbedVideo",
          component: EmbedVideo,
          route: PrivateRoute,
        },
        {
          path: "/ui/grid",
          name: "Grid",
          component: Grid,
          route: PrivateRoute,
        },
        {
          path: "/ui/listgroups",
          name: "List Groups",
          component: ListGroups,
          route: PrivateRoute,
        },
        {
          path: "/ui/modals",
          name: "Modals",
          component: Modals,
          route: PrivateRoute,
        },
        {
          path: "/ui/notifications",
          name: "Notifications",
          component: Notifications,
          route: PrivateRoute,
        },
        {
          path: "/ui/offcanvas",
          name: "Offcanvas",
          component: Offcanvases,
          route: PrivateRoute,
        },
        {
          path: "/ui/paginations",
          name: "Paginations",
          component: Paginations,
          route: PrivateRoute,
        },
        {
          path: "/ui/popovers",
          name: "Popovers",
          component: Popovers,
          route: PrivateRoute,
        },
        {
          path: "/ui/progress",
          name: "Progress",
          component: Progress,
          route: PrivateRoute,
        },
        {
          path: "/ui/ribbons",
          name: "Ribbons",
          component: Ribbons,
          route: PrivateRoute,
        },
        {
          path: "/ui/spinners",
          name: "Spinners",
          component: Spinners,
          route: PrivateRoute,
        },
        {
          path: "/ui/tabs",
          name: "Tabs",
          component: Tabs,
          route: PrivateRoute,
        },
        {
          path: "/ui/tooltips",
          name: "Tooltips",
          component: Tooltips,
          route: PrivateRoute,
        },
        {
          path: "/ui/typography",
          name: "Typography",
          component: Typography,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/ui/extended",
      name: "Extended UI",
      children: [
        {
          path: "/ui/dragdrop",
          name: "Drag and Drop",
          component: DragDrop,
          route: PrivateRoute,
        },
        {
          path: "/ui/rangesliders",
          name: "Range Sliders",
          component: RangeSliders,
          route: PrivateRoute,
        },
        {
          path: "/ui/ratings",
          name: "Ratings",
          component: Ratings,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/ui/widgets",
      name: "Widgets",
      component: Widgets,
      route: PrivateRoute,
    },
    {
      path: "/ui/icons",
      name: "Icons",
      children: [
        {
          path: "/ui/icons/dripicons",
          name: "Dripicons",
          component: Dripicons,
          route: PrivateRoute,
        },
        {
          path: "/ui/icons/mdi",
          name: "Material Design",
          component: MDIIcons,
          route: PrivateRoute,
        },
        {
          path: "/ui/icons/unicons",
          name: "Unicons",
          component: Unicons,
          route: Unicons,
        },
      ],
    },
    {
      path: "/ui/forms",
      name: "Forms",
      children: [
        {
          path: "/ui/forms/basic",
          name: "Basic Elements",
          component: BasicForms,
          route: PrivateRoute,
        },
        {
          path: "/ui/forms/advanced",
          name: "Form Advanced",
          component: FormAdvanced,
          route: PrivateRoute,
        },
        {
          path: "/ui/forms/validation",
          name: "Form Validation",
          component: FormValidation,
          route: PrivateRoute,
        },
        {
          path: "/ui/forms/wizard",
          name: "Form Wizard",
          component: FormWizard,
          route: PrivateRoute,
        },
        {
          path: "/ui/forms/upload",
          name: "File Upload",
          component: FileUpload,
          route: PrivateRoute,
        },
        {
          path: "/ui/forms/editors",
          name: "Editors",
          component: Editors,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/ui/charts",
      name: "Charts",
      children: [
        {
          path: "/ui/charts/apex",
          name: "Apex",
          component: ApexChart,
          route: PrivateRoute,
        },
        {
          path: "/ui/charts/brite",
          name: "Brite",
          component: BriteChart,
          route: PrivateRoute,
        },
        {
          path: "/ui/charts/chartjs",
          name: "Chartjs",
          component: ChartJs,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/ui/tables",
      name: "Tables",
      children: [
        {
          path: "/ui/tables/basic",
          name: "Basic",
          component: BasicTables,
          route: PrivateRoute,
        },
        {
          path: "/ui/tables/advanced",
          name: "Advanced",
          component: AdvancedTables,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/ui/maps",
      name: "Maps",
      children: [
        {
          path: "/ui/googlemaps",
          name: "Google Maps",
          component: GoogleMaps,
          route: PrivateRoute,
        },
        {
          path: "/ui/vectorMaps",
          name: "Google Maps",
          component: VectorMaps,
          route: PrivateRoute,
        },
      ],
    },
  ],
};

const otherPublicRoutes = [
  {
    path: "/landing",
    name: "landing",
    component: Landing,
    route: Route,
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: Maintenance,
    route: Route,
  },
  {
    path: "/error-404",
    name: "Error - 404",
    component: ErrorPageNotFound,
    route: Route,
  },
  {
    path: "/error-500",
    name: "Error - 500",
    component: ServerError,
    route: Route,
  },
  {
    path: "/account/confirm",
    name: "Confirm",
    component: Confirm,
    route: Route,
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
  let flatRoutes = [];

  routes = routes || [];
  routes.forEach((item) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// auth
const authRoutes = [
  {
    path: "/account/login",
    name: "Login",
    component: Login,
    route: Route,
  },
  {
    path: "/account/logout",
    name: "Logout",
    component: Logout,
    route: Route,
  },
  {
    path: "/account/register",
    name: "Register",
    component: Register,
    route: Route,
  },
  {
    path: "/account/forget-password",
    name: "Forget Password",
    component: ForgetPassword,
    route: Route,
  },
  {
    path: "/account/lock-screen",
    name: "Lock Screen",
    component: LockScreen,
    route: Route,
  },
  {
    path: "/account/login2",
    name: "Login2",
    component: Login2,
    route: Route,
  },
  {
    path: "/account/logout2",
    name: "Logout2",
    component: Logout2,
    route: Route,
  },
  {
    path: "/account/register2",
    name: "Register2",
    component: Register2,
    route: Route,
  },
  {
    path: "/account/confirm2",
    name: "Confirm2",
    component: Confirm2,
    route: Route,
  },
  {
    path: "/account/forget-password2",
    name: "Forget Password2",
    component: ForgetPassword2,
    route: Route,
  },
  {
    path: "/account/lock-screen2",
    name: "Lock Screen2",
    component: LockScreen2,
    route: Route,
  },
];

// account route
const accountRoute = {
  path: "/accounts",
  name: "accounts",
  header: "계정",
  children: [
    {
      path: "/account/myInfo",
      name: "프로필",
      component: MyInfoPage,
      route: PrivateRoute,
    },
    {
      path: "/account/admin/lists",
      name: "어므민 관리",
      component: AdminAccountPage,
      route: PrivateRoute,
    },
  ],
};

// contract route
const contractRoute = {
  path: "/contract",
  name: "contract",
  header: "컨트랙트",
  children: [
    {
      path: "/contract/pool-lists",
      name: "풀 목록",
      component: PoolListsPage,
      route: PrivateRoute,
    },
    {
      path: "/contract/withdraw",
      name: "풀 강제출금",
      component: PoolWithdrawPage,
      route: PrivateRoute,
    },
    {
      path: "/user/withdraw",
      name: "사용자 강제출금",
      component: UserWithdrawPage,
      route: PrivateRoute,
    },
    {
      path: "/contract/pool-access",
      name: "풀 접근목록",
      component: PoolAccessPage,
      route: PrivateRoute,
    },
  ],
};

// All routes
const authProtectedRoutes = [
  rootRoute,
  dashboardRoutes,
  ...appRoutes,
  pageRoutes,
  uiRoutes,
  accountRoute,
  contractRoute,
];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);

export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
};
