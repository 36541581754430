import React from "react";
import moment from "moment";

import PageTitle from "../../components/PageTitle";
import { Badge, Button, Card, Col, Row } from "react-bootstrap";
import Table from "../../components/Table";
import { useEffect, useState } from "react";
import {
  deleteContractPool,
  deployContract,
  getAllContractPool,
  transferLiquidity,
} from "../../api/contract";
import classNames from "classnames";
import styled from "styled-components";
import AddPoolModal from "../../components/Modal/AddPoolModal";
import { ToastContainer } from "react-toastify";
import { notify } from "../../utils/toast";
import BaseDeleteModal from "../../components/BaseDeleteModal";
import UpdateContractPoolModal from "../../components/Modal/UpdateContractPoolModal";
import ConfirmModal from "../../components/Modal/ConfirmModal";

const RowContainer = styled(Row)`
  section {
    position: relative;
  }

  .add-btn {
    position: absolute;
    right: 30px;
  }
`;

const PoolListsPage = () => {
  const [chargeGroup, setChargeGroup] = useState([]);
  const [lockerGroup, setLockerGroup] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectId, setSelectId] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transferLoading, setTransferLoading] = useState(false);
  const [transferModal, setTransferModal] = useState(false);
  const [selectDeployData, setSelectDeployData] = useState(null);

  const transferToggle = () => {
    setTransferModal(!transferModal);
  };
  const confirmToggle = () => {
    setConfirmModal(!confirmModal);
  };

  const deleteToggle = () => {
    setDeleteModal(!deleteModal);
  };

  const updateToggle = () => {
    setUpdateModal(!updateModal);
  };

  const updateOpenToggle = () => {
    setUpdateModal(true);
  };

  const addToggle = () => {
    setAddModal(!addModal);
  };

  const onTransfer = async () => {
    try {
      if (selectId && !loading) {
        const body = {
          seq: Number(selectDeployData.seq),
          network: selectDeployData.network,
        };

        setTransferLoading(true);
        await transferLiquidity(selectId, body);
        setTransferLoading(false);
        bootstrap();
        notify(true);
      }
    } catch (err) {
      notify(false);
      setTransferLoading(false);
    }
  };

  const onDeployContract = async () => {
    try {
      if (selectId && !loading && selectDeployData) {
        const body = {
          seq: Number(selectDeployData.seq),
          network: selectDeployData.network,
        };
        setLoading(true);
        await deployContract(selectId, body);
        bootstrap();
        notify(true);
        confirmToggle();
        setLoading(false);
      }
    } catch (err) {
      notify(false);
      setLoading(false);
    }
  };

  const deletePoolHandler = async () => {
    try {
      await deleteContractPool(selectId);
      notify(true);
      bootstrap();
      deleteToggle();
    } catch (err) {
      notify(false);
    }
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "순서",
        accessor: "seq",
        sort: true,
      },
      {
        Header: "이름",
        accessor: "name",
        Cell: (data) => (
          <a
            style={{ cursor: "pointer", color: "#727cf5" }}
            onClick={() => {
              console.log("click", data.row.values.idx);
              setSelectId(data.row.values.idx);
              updateOpenToggle();
            }}
          >
            {data.row.values.name}
          </a>
        ),
      },

      {
        Header: "주소",
        accessor: "address",
        Cell: (data) => (
          <>
            <div>
              {data.row.values.address ? (
                <a
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                  href={data.row.original.url}
                  target="_blank"
                >
                  {data.row.values.address}
                </a>
              ) : (
                <></>
              )}
            </div>
          </>
        ),
      },

      {
        Header: "네트워크",
        accessor: "network",
      },
      {
        Header: "minimum",
        accessor: "minimum",
      },
      {
        Header: "liquidity",
        accessor: "liquidity",
      },

      {
        Header: "활성화",
        accessor: "enabled",
        Cell: (data) => (
          <div>
            {data.row.values.enabled ? (
              <Badge
                style={{ padding: "10px", color: "#099268" }}
                className={classNames("me-1", "badge bg-secondary-lighten")}
              >
                활성화
              </Badge>
            ) : (
              <Badge
                style={{ padding: "10px", color: "#f03e3e" }}
                className={classNames("me-1", "badge bg-secondary-lighten")}
              >
                비화성화
              </Badge>
            )}
          </div>
        ),
      },
      {
        Header: "locked",
        accessor: "locked",
        Cell: (data) => (
          <div>
            {data.row.values.locked ? (
              <Badge
                style={{ padding: "10px", color: "#099268" }}
                className={classNames("me-1", "badge bg-secondary-lighten")}
              >
                locked
              </Badge>
            ) : (
              <Badge
                style={{ padding: "10px", color: "#f03e3e" }}
                className={classNames("me-1", "badge bg-secondary-lighten")}
              >
                unlocked
              </Badge>
            )}
          </div>
        ),
      },
      {
        Header: "start",
        accessor: "start",
        Cell: (data) => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {moment(data.row.values.start).format("YYYY-MM-DD HH:mm")}
            </div>
          );
        },
      },
      {
        Header: "finish",
        accessor: "finish",
        Cell: (data) => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {moment(data.row.values.finish).format("YYYY-MM-DD HH:mm")}
            </div>
          );
        },
      },
      {
        Header: "timestamp",
        accessor: "timestamp",
        sort: true,
        Cell: (data) => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {moment(data.row.values.timestamp).format("YYYY-MM-DD HH:mm")}
            </div>
          );
        },
      },
      {
        Header: "memo",
        accessor: "memo",
      },
      {
        Header: "배포",
        // accessor: "name",
        Cell: (data) =>
          !data.row.values.address ? (
            <a
              style={{ cursor: "pointer", color: "#38d9a9" }}
              onClick={() => {
                console.log(data.row.values.address);
                setSelectDeployData(data.row.values);
                setSelectId(data.row.values.idx);
                confirmToggle();
              }}
            >
              배포
            </a>
          ) : (
            <a style={{ color: "gray", cursor: "not-allowed" }}>배포</a>
          ),
      },

      {
        Header: "보상",
        accessor: "skipLiquidity",
        Cell: (data) => {
          return data.row.values.skipLiquidity ? (
            <a
              style={{ cursor: "pointer", color: "#38d9a9" }}
              onClick={() => {
                console.log(data.row.values.idx);
                setSelectDeployData(data.row.values);
                setSelectId(data.row.values.idx);
                transferToggle();
              }}
            >
              전송
            </a>
          ) : (
            <a
              style={{ color: "gray", cursor: "not-allowed" }}
              onClick={() => {
                console.log(data.row.values.skipLiquidity, data.row.values);
              }}
            >
              전송
            </a>
          );
        },
      },

      // {
      //   Header: "일시",
      //   accessor: "timestamp",
      //   sort: true,
      //   Cell: (data) => (
      //     <div>{moment(data.row.cells[13].value).format("YYYY-MM-DD")}</div>
      //   ),
      // },
      {
        Header: "액션",
        accessor: "idx",
        Cell: (data) => {
          return (
            <>
              <span
                className="action-icon"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSelectId(data.row.original.idx);
                  deleteToggle();
                }}
              >
                <i className="mdi mdi-delete" />
              </span>
            </>
          );
        },
      },

      // {
      //   Header: "타입",
      //   accessor: "type",
      // },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(chargeGroup), JSON.stringify(lockerGroup)]
  );

  // const lockerColumns = React.useMemo(
  //   () => [
  //     {
  //       Header: "타입",
  //       accessor: "type",
  //     },
  //     {
  //       Header: "네트워크",
  //       accessor: "network",
  //     },
  //     {
  //       Header: "주소",
  //       accessor: "address",
  //     },
  //   ],
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [JSON.stringify(lockerGroup)]
  // );

  const sizePerPageList = [
    {
      text: "10",
      value: 10,
    },
    {
      text: "20",
      value: 20,
    },
    {
      text: "50",
      value: 50,
    },
    {
      text: "100",
      value: 100,
    },
  ];

  const bootstrap = async () => {
    const getPoolLists = await getAllContractPool();

    let allChargerLists = [];
    Object.keys(getPoolLists.chargeList).forEach((key, i) => {
      allChargerLists.push(...getPoolLists.chargeList[key]);
    });
    // let allChargerLists = [
    //   ...getPoolLists.chargeList.BSC,
    //   ...getPoolLists.chargeList.ETH,
    //   ...getPoolLists.chargeList.HECO,
    // ];

    allChargerLists = allChargerLists.map((charge) => {
      return {
        ...charge,
        type: "charge list",
      };
    });
    let lockLists = [];
    Object.keys(getPoolLists.privateLocker).forEach((key, i) => {
      lockLists.push(...getPoolLists.privateLocker[key]);
    });

    lockLists = lockLists.map((lock) => {
      return {
        ...lock,
        type: "private locker",
      };
    });
    setChargeGroup(allChargerLists);
    setLockerGroup(lockLists);
  };

  useEffect(() => {
    bootstrap();
  }, []);
  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "풀 관리", path: "/contract/pool-lists", active: true },
        ]}
        title="풀 관리"
      />
      <div>
        <RowContainer>
          <Col style={{ paddingLeft: "10px" }}>
            <Button className="add-btn" onClick={addToggle}>
              추가
            </Button>
            <Card style={{ marginTop: "50px" }}>
              <Card.Body>
                <section>
                  <Table
                    columns={columns}
                    data={chargeGroup}
                    pageSize={20}
                    sizePerPageList={sizePerPageList}
                    isSortable={true}
                    pagination={true}
                    isSearchable={true}
                    theadClass="table-light"
                    searchBoxClass="mt-2 mb-3"
                  />
                </section>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{ marginTop: "20px" }}>
            <Card>
              <Card.Body>
                <Table
                  columns={columns}
                  data={lockerGroup}
                  pageSize={20}
                  sizePerPageList={sizePerPageList}
                  isSortable={true}
                  pagination={true}
                  isSearchable={false}
                  theadClass="table-light"
                  searchBoxClass="mt-2 mb-3"
                />
              </Card.Body>
            </Card>
          </Col>
        </RowContainer>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <AddPoolModal
        modal={addModal}
        toggle={addToggle}
        getPoolList={bootstrap}
      />

      <UpdateContractPoolModal
        modal={updateModal}
        toggle={updateToggle}
        selectId={selectId}
        getLists={bootstrap}
      />

      <BaseDeleteModal
        modal={deleteModal}
        toggle={deleteToggle}
        onHandler={deletePoolHandler}
      />

      <ConfirmModal
        modal={confirmModal}
        toggle={confirmToggle}
        onHandler={onDeployContract}
        loading={loading}
        setLoading={setLoading}
      />

      <ConfirmModal
        modal={transferModal}
        toggle={transferToggle}
        onHandler={onTransfer}
        loading={transferLoading}
        message="전송을 할까요?"
        setLoading={setTransferLoading}
      />
    </>
  );
};

export default PoolListsPage;
