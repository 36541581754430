import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { FormInput, VerticalForm } from "../index";
import styled from "styled-components";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import DatePicker from "react-datepicker";
import useInput from "../../hooks/useInput";
import moment from "moment";
import {
  addContractPool,
  getAllContractPool,
  getLiquidities,
} from "../../api/contract";
import { toast } from "react-toastify";
const DropDownContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  > div > div {
    height: 200px;
    overflow-y: auto;
  }

  .bottom-start {
    height: 120px;
  }
`;

const AddPoolModal = ({ modal, toggle, getPoolList }) => {
  const today = new Date();
  const lastDayOfMonth = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    0,
    14
  );
  const { Header, Body, Footer } = Modal;
  const [network, setNetwork] = useState("BSC");
  const [locked, setLocked] = useState(false);
  const [start, setStart] = useState(new Date());
  const [finish, setFinish] = useState(lastDayOfMonth);
  const [name, onChangeName, setName] = useInput("");
  const [address, onChangeAddress, setAddress] = useInput("");
  const [minimum, onChangeMinimum, setMinimum] = useInput("0");
  const [memo, onChangeMemo, setMemo] = useInput("");
  const [isPrivate, setIsPrivate] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [seq, onChangeSeq, setSeq] = useInput("0");
  const [liquidity, onChangeLiquidity, setLiquidity] = useInput("1");
  const [error, setError] = useState(false);
  const [skipLiquidity, setSkipLiquidity] = useState(false);

  const [networkLists, setNetworkLists] = useState([]);

  const [poolNetwork, setPoolNetwork] = useState([]);

  const [reward, setReward] = useState("");
  const [staking, setStaking] = useState("");

  const notify = (isSuccess) => {
    if (isSuccess) {
      return toast.success("성공적으로 처리 되었습니다.");
    }
    return toast.error("요청에 실패했습니다.");
  };

  const onSubmit = async () => {
    if (parseInt(liquidity) < 1) {
      setError(true);
      return;
    } else {
      setError(false);
    }

    const body = {
      name,
      address: address ? address : null,
      memo,
      minimum: minimum === "" ? 0 : Number(minimum),
      start: moment(start).unix() * 1000,
      finish: moment(finish).unix() * 1000,
      locked,
      network,
      private: isPrivate,
      enabled: enabled ? 1 : 0,
      seq: Number(seq),
      liquidity: liquidity === "" ? 0 : Number(liquidity),
      staking: staking ? staking : null,
      reward: reward ? reward : null,
      skipLiquidity,
    };

    try {
      await addContractPool(body);
      await getPoolList();
      toggle();
      notify(true);
      setNetwork(networkLists[0] || "");
      setName("");
      setAddress("");
      setMemo("");
      setLiquidity("1");
      setSeq("");
      setLocked(false);
      setStart(new Date());
      setFinish(lastDayOfMonth);
      setIsPrivate(false);
      setMinimum("1");
      setReward("");
      setStaking("");
      setError(false);
      setSkipLiquidity(false);
    } catch (err) {
      console.log(err);
      notify(false);
    }
  };

  const schemaResolver = yupResolver(
    yup.object().shape({
      name: yup.string().required("이름을 입력하세요"),
      address: yup.string().required("주소를 입력하세요"),
      minimum: yup.string().required("minimum 입력하세요"),
      memo: yup.string().required("memo 입력하세요"),
      // contact: yup.string().required("연락처를 입력하세요"),
    })
  );

  const NetworkDropDown = () => {
    return networkLists.map((pool, i) => (
      <Dropdown.Item
        key={i}
        onClick={(e) => {
          setNetwork(e.target.textContent);
        }}
      >
        {pool}
      </Dropdown.Item>
    ));
  };

  const RewardDropDown = () => {
    return poolNetwork.map((item, i) => (
      <Dropdown.Item
        key={i}
        onClick={(e) => {
          setReward(e.target.textContent.split(" ")[1]);
        }}
      >
        {`[${item.type}] ${item.contract}`}
      </Dropdown.Item>
    ));
  };

  const StakingDropDown = () => {
    return poolNetwork.map((item, i) => (
      <Dropdown.Item
        key={i}
        onClick={(e) => {
          setStaking(e.target.textContent.split(" ")[1]);
        }}
      >
        {`[${item.type}] ${item.contract}`}
      </Dropdown.Item>
    ));
  };

  useEffect(() => {
    bootstrap();
  }, [modal]);

  const bootstrap = async () => {
    const result = await getLiquidities();
    const getPoolLists = await getAllContractPool();
    const keys = Object.keys(getPoolLists.chargeList);
    setNetworkLists(keys);
    setPoolNetwork(result);
  };

  return (
    <Modal
      show={modal}
      onHide={() => {
        toggle();
        setNetwork(networkLists[0] || "");
        setName("");
        setAddress("");
        setMemo("");
        setLiquidity("1");
        setSeq("");
        setLocked(false);
        setStart(new Date());
        setFinish(lastDayOfMonth);
        setIsPrivate(false);
        setMinimum("0");
        setError(false);
        setSkipLiquidity(false);
      }}
      size="lg"
    >
      <Header
        onHide={() => {
          toggle();
          setNetwork(networkLists[0] || "");
          setName("");
          setAddress("");
          setMemo("");
          setLocked(false);
          setStart(new Date());
          setFinish(lastDayOfMonth);
          setIsPrivate(false);
          setMinimum("0");
          setEnabled(false);
          setStaking("");
          setReward("");
          setLiquidity("1");
          setError(false);
          setSkipLiquidity(false);
        }}
        closeButton
      >
        <h4 className="modal-title">풀 추가</h4>
      </Header>

      <Body>
        <VerticalForm resolver={schemaResolver} defaultValues={{}}>
          <Row>
            <Col md={12}>
              <FormInput
                label="이름"
                type="text"
                name="name"
                placeholder="이름를 입력하세요"
                containerClass={"mb-3"}
                value={name}
                onChange={onChangeName}
              />
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <FormInput
                label="주소"
                type="text"
                name="address"
                placeholder="주소를 입력하세요"
                containerClass={"mb-3"}
                value={address}
                onChange={onChangeAddress}
              />
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Form.Label>보상 풀</Form.Label>
              <DropDownContainer>
                <DropdownButton variant="light" title={reward}>
                  {RewardDropDown()}
                </DropdownButton>
              </DropDownContainer>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Form.Label>스테이킹 풀</Form.Label>
              <DropDownContainer>
                <DropdownButton variant="light" title={staking}>
                  {StakingDropDown()}
                </DropdownButton>
              </DropDownContainer>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <Form.Label>블록체인</Form.Label>
              <DropDownContainer>
                <DropdownButton variant="light" title={network}>
                  {NetworkDropDown()}
                </DropdownButton>
              </DropDownContainer>
            </Col>

            <Col md={6}>
              <FormInput
                label="최소 갯수"
                type="number"
                name="minimum"
                placeholder="minimum를 입력하세요"
                containerClass={"mb-3"}
                value={minimum}
                onChange={(e) => {
                  let amount = parseInt(e.target.value);
                  if (
                    amount < 0
                    // e.target.value[0] === "0"
                  ) {
                    return;
                  }
                  setMinimum(amount);
                }}
                min="0"
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Label>Locked</Form.Label>
              <div>
                <BootstrapSwitchButton
                  onstyle="outline-primary"
                  offstyle="outline-secondary"
                  width={100}
                  checked={locked}
                  onlabel="Locked"
                  offlabel="Unlocked"
                  onChange={() => {
                    setLocked(!locked);
                  }}
                />
              </div>
            </Col>

            <Col md={6}>
              <Form.Label>Private 여부</Form.Label>
              <div>
                <BootstrapSwitchButton
                  onstyle="outline-primary"
                  offstyle="outline-secondary"
                  width={100}
                  checked={isPrivate}
                  onlabel="ON"
                  offlabel="OFF"
                  onChange={() => {
                    setIsPrivate(!isPrivate);
                  }}
                />
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: "20px" }}>
            <Col md={6}>
              <Form.Label>활성화 여부</Form.Label>
              <div>
                <BootstrapSwitchButton
                  onstyle="outline-primary"
                  offstyle="outline-secondary"
                  width={100}
                  checked={enabled}
                  onlabel="ON"
                  offlabel="OFF"
                  onChange={() => {
                    setEnabled(!enabled);
                  }}
                />
              </div>
            </Col>

            <Col md={6}>
              <FormInput
                label="memo"
                type="text"
                name="memo"
                placeholder="memo를 입력하세요"
                containerClass={"mb-3"}
                value={memo}
                onChange={onChangeMemo}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Label>풀 시작</Form.Label>
              <DatePicker
                selected={start}
                dateFormat="yyyy/MM/dd HH:mm"
                onChange={(date) => setStart(date)}
                selectsStart
                startDate={start}
                endDate={finish}
                showTimeSelect
                style={{ width: "100px" }}
              />
            </Col>
            <Col md={6}>
              <Form.Label>풀 종료</Form.Label>
              <DatePicker
                selected={finish}
                dateFormat="yyyy/MM/dd HH:mm"
                onChange={(date) => setFinish(date)}
                selectsEnd
                startDate={start}
                endDate={finish}
                showTimeSelect
                minDate={start}
              />
            </Col>
          </Row>

          <Row style={{ marginTop: "10px" }}>
            <Col md={6}>
              <FormInput
                label="seq"
                type="number"
                name="seq"
                placeholder="seq 입력하세요"
                containerClass={"mb-3"}
                value={seq}
                onChange={onChangeSeq}
              />
            </Col>

            <Col md={6}>
              <FormInput
                label="liquidity"
                type="number"
                name="liquidity"
                placeholder="liquidity 입력하세요"
                containerClass={"mb-3"}
                value={liquidity}
                onChange={(e) => {
                  let amount = parseInt(e.target.value);
                  if (amount < 0) {
                    return;
                  }
                  setLiquidity(amount);
                }}
                min="1"
              />
              {error && (
                <div style={{ color: "red", fontWeight: "bold" }}>
                  liquidity는 1이상이어야 합니다.
                </div>
              )}
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Label>skip liquidity</Form.Label>
              <div>
                <BootstrapSwitchButton
                  onstyle="outline-primary"
                  offstyle="outline-secondary"
                  width={100}
                  checked={skipLiquidity}
                  onlabel="활성화"
                  offlabel="비활성화"
                  onChange={() => {
                    setSkipLiquidity(!skipLiquidity);
                  }}
                />
              </div>
            </Col>
          </Row>
        </VerticalForm>
      </Body>

      <Footer>
        <Button variant="primary" type="submit" onClick={onSubmit}>
          생성
        </Button>
      </Footer>
    </Modal>
  );
};

export default AddPoolModal;
