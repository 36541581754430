import { Button, Modal } from "react-bootstrap";
import React from "react";

const BaseDeleteModal = ({
  modal,
  toggle,
  onHandler,
  title = "삭제",
  message = "정말로 삭제하시겠습니까?",
}) => {
  return (
    <Modal show={modal} onHide={toggle} size="md">
      <Modal.Header onHide={toggle} closeButton>
        <h4 className="modal-title">{title}</h4>
      </Modal.Header>
      <Modal.Body>
        <div>{message}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onHandler}>
          확인
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BaseDeleteModal;
