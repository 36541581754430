import React from 'react';
import ReactDOM from 'react-dom';

import './i18n';

import App from './App';

import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import {RecoilRoot} from "recoil";

// if (process.env.NODE_ENV !== "development") console.log = () => {};
console.log(process.env.REACT_APP_ENV_API, 'env22')

ReactDOM.render(
    <RecoilRoot>
        <Provider store={configureStore({})}>
            <App />
        </Provider>
    </RecoilRoot>,
    document.getElementById('root')
);
