import { APICore } from "../helpers/api/apiCore";

const api = new APICore();

const contractDomain = "/blockchain/contracts";

export const getAllContractPool = async () => {
  return await api.get("/blockchain/contracts").then((resp) => resp.data.data);
};

export const addContractPool = async (body) => {
  return await api.create(contractDomain, body);
};

export const deleteContractPool = async (address) => {
  return await api
    .delete(`${contractDomain}/${address}`)
    .then((resp) => resp.data);
};

export const getContractPool = async (idx) => {
  return await api
    .get(`${contractDomain}/${idx}`)
    .then((resp) => resp.data.data.contract);
};

export const updateContractPool = async (idx, body) => {
  return await api
    .create(`${contractDomain}/${idx}`, body)
    .then((resp) => resp.data);
};

export const deployContract = async (idx, body) => {
  return await api
    .create(`${contractDomain}/${idx}/deploy`, body)
    .then((resp) => resp.data);
};

// /blockchain/liquidities

export const getLiquidities = async () => {
  const baseUrl = "/blockchain/liquidities";
  return await api.get(baseUrl).then((resp) => resp.data.data);
};

export const transferLiquidity = async (idx, body) => {
  const baseUrl = `/blockchain/contracts/${idx}/liquidity`;
  return await api.create(baseUrl, body).then((resp) => resp.data.data);
};

export const requestWithdraw = async (idx, body) => {
  const baseUrl = `/blockchain/contracts/${idx}/withdraw`;
  return await api.create(baseUrl, body).then((resp) => resp.data.data);
};

export const userRequestWithdraw = async (idx, body) => {
  return api
    .create(`/blockchain/contracts/${idx}/withdraw/rewards`, body)
    .then((resp) => resp.data.data);
};
